// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-2019-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/2019.js" /* webpackChunkName: "component---src-pages-2019-js" */),
  "component---src-pages-about-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/join.js" /* webpackChunkName: "component---src-pages-join-js" */),
  "component---src-pages-product-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-protocol-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/protocol.js" /* webpackChunkName: "component---src-pages-protocol-js" */),
  "component---src-pages-solution-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/solution.js" /* webpackChunkName: "component---src-pages-solution-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/atlassian/pipelines/agent/build/.cache/data.json")

